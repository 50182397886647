<template>
  <div>
    <h2>Заказы</h2>
    <router-view/>
  </div>
</template>

<script>
  export default {
    name: "Index",
  }
</script>

<style scoped>
  
</style>