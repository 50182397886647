<template>
  <div>
    <h2>Статистика</h2>
  </div>
</template>

<script>
  export default {
    name: "Statistics"
  }
</script>

<style scoped>

</style>