<template>
  <v-app-bar density="compact">
    <v-app-bar-title>Hotel-manager</v-app-bar-title>
    <v-spacer></v-spacer>
  </v-app-bar>
</template>

<script>
  export default {
    name: "MainHeader"
  }
</script>

<style scoped>

</style>