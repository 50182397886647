<template>
  <div>DetailedFoodCategory</div>
</template>

<script>
  export default {
    name: "DetailedFoodCategory"
  }
</script>

<style scoped>

</style>