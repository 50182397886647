<template>
  <div>
    <h2>Категории блюд</h2>
    <router-view />
  </div>
</template>

<script>
  export default {
    name: "Index",
  }
</script>

<style scoped>
  .wrapper{
    width: 100%;
  }
  
</style>