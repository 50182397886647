<template>
  <div>DetailedIngredients</div>
</template>

<script>
  export default {
    name: "DetailedIngredient"
  }
</script>

<style scoped>

</style>