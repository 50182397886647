<template>
  <v-row cols="12" md="12">
    <v-col cols="12" md="12">
      <h2>Рестораны</h2>
      <router-view/>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: "Index",
  }
</script>

<style scoped>
  
</style>